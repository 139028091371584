var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageData.colorClass)?_c('div',{staticClass:"key-figures-page"},[_c('div',{staticClass:"modal",class:{ 'is-active': _vm.showNoGraphicIE11Modal }},[_c('div',{staticClass:"modal-background"}),_c('div',{staticClass:"modal-content"},[_c('div',{staticStyle:{"color":"white"}},[_vm._v(" Die Funktion \"Grafik exportieren\" wird im Internet Explorer nicht unterstützt ")]),_c('div',{staticStyle:{"margin-top":"1em"}},[_c('button',{staticClass:"modal-close-button",on:{"click":function($event){return _vm.closeIE11WarningModal()}}},[_vm._v(" Schliessen ")])])]),_c('button',{staticClass:"modal-close is-large",on:{"click":function($event){return _vm.closeIE11WarningModal()}}})]),_c('core-figures-header',{attrs:{"title":_vm.pageData.pageTitle,"text":_vm.pageData.pageHeaderText,"color-class":'cf-header--' + _vm.pageData.colorClass}}),(_vm.mainFilter)?_c('section',{staticClass:"section snf-section snf-section__blue-light padding-top-1"},[_c('div',{staticClass:"columns snf-section__columns"},[(_vm.mainFilter)?_c('div',{staticClass:"container column is-full"},[_c('div',{staticClass:"cf-main-filter"},[_c('div',{staticClass:"cf-main-filter__mobile-item"},[_c('SolrSelect',{attrs:{"parsed-entry":_vm.mainFilter},on:{"solrSelectValueChanged":_vm.solrSelectValueChanged}})],1),_vm._l((_vm.mainFilter.selectionValues),function(value,index){return _c('div',{key:index,staticClass:"cf-main-filter__item",class:{
              'cf-main-filter__item--selected': index === _vm.mainFilter.selectedIndex,
            },on:{"click":function($event){return _vm.mainFilterValueChanged(value)}}},[_vm._v(" "+_vm._s(value.text)+" ")])})],2)]):_vm._e()])]):_vm._e(),_c('section',{staticClass:"section snf-section padding-top-1",class:{ 'snf-section__blue-light': !_vm.mainFilter }},[_c('div',{staticClass:"columns snf-section__columns"},[_c('div',{staticClass:"container column is-full"},[(_vm.cfParseSentenceResult)?_c('div',{staticClass:"field is-grouped",staticStyle:{"flex-wrap":"wrap","justify-content":"flex-start"}},_vm._l((_vm.cfParseSentenceResult.sentenceParts),function(part,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(part !== _vm.mainFilterIndex),expression:"part !== mainFilterIndex"}],key:part + index,staticClass:"control cf-control",style:({
              'margin-bottom': '0.5em',
              'margin-right': part[0] === '*' ? '0.75rem' : 0,
            })},[(part[0] === '*')?_c('SolrSelect',{attrs:{"parsed-entry":_vm.cfParseSentenceResult.parsedEntries[part]},on:{"solrSelectValueChanged":_vm.solrSelectValueChanged}}):_vm._e(),(part[0] !== '*')?_c('input',{staticClass:"input cf-input",class:{ 'cf-input--blue-light': !_vm.mainFilterIndex },style:({
                border: 'none',
                width: _vm.getTextWidth(part) + 20 + 'px',
                background: '#f0f0f0',
                'margin-left': part === ')' ? '-0.75rem' : 0,
                'margin-right': part === '(' ? '-0.75rem' : 0,
              }),attrs:{"type":"text","tabindex":"-1","aria-label":part,"readonly":""},domProps:{"value":part}}):_vm._e()],1)}),0):_vm._e()])])]),_c('Loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoader || _vm.showLoaderHard),expression:"showLoader || showLoaderHard"}]}),(!_vm.showLoaderHard)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showLoader),expression:"!showLoader"}]},[(_vm.noEntityData && !_vm.showNoPublishedDataMessage)?_c('section',{staticClass:"section snf-section margin-top-1"},[_c('div',{staticClass:"columns snf-section__columns"},[_c('div',{staticClass:"container column is-full"},[_c('div',{staticClass:"no-data"},[_vm._v(" "+_vm._s(_vm.noEntityData)+" ")])])])]):_c('div',[_c('section',{staticClass:"section snf-section"},[_c('div',{staticClass:"columns snf-section__columns"},[_c('div',{staticClass:"container column is-full"},[(!_vm.showNoPublishedDataMessage)?_c('div',{staticClass:"cf-result-sentence",domProps:{"innerHTML":_vm._s(_vm.cfResultSentence)}}):_vm._e()])])]),_c('section',{staticClass:"section snf-section graph-section margin-top-1 padding-bottom-2"},[_c('div',{staticClass:"columns snf-section__columns"},[(_vm.showNoPublishedDataMessage)?_c('div',{staticClass:"no-data-message"},[_c('div',[_c('NoDataMessage')],1)]):(
              _vm.pageData.entityUrl === 'demographics' &&
              _vm.$route.query.s1 === _vm.demographicsComparisonIndex
            )?_c('div',{staticStyle:{"width":"100%"}},[_c('div',{attrs:{"id":"demographics-pyramid-id"}})]):(_vm.pageData.entityUrl === 'demographics')?_c('div',[_c('div',{class:{
                'britecharts-chart-horizontal': _vm.demographicsChartHorizontal,
                'britecharts-chart-vertical': !_vm.demographicsChartHorizontal,
              },style:({}),attrs:{"id":"britecharts-chart"}})]):(_vm.pageData.entityUrl === 'internationality')?_c('div',{staticClass:"internationality-section"},[_vm._m(0),_c('div',{staticClass:"internationality-loader-wrapper"},[(_vm.showLoaderInternationality)?_c('div',{staticClass:"internationality-loader"}):_vm._e()]),_c('div',{attrs:{"id":"internationality-legend-id"}}),_c('div',{attrs:{"id":"internationality-legend-table-id"}},[_c('table',{staticClass:"country-table"},[_c('tbody',_vm._l((_vm.internationalityTableData),function(item){return _c('tr',{key:item.label},[_c('td',{staticClass:"country-label"},[_c('div',{staticClass:"country-square",style:({
                          background: _vm.interpolateCountryColor(item.count),
                        })}),_vm._v(" "+_vm._s(item.label)+" ")]),_c('td',{staticClass:"country-count"},[_vm._v(" "+_vm._s(_vm.numberToStringFormat(item.count))+" ")])])}),0)])])]):(_vm.cfSelectedParts['*1'].solrValue === '3')?_c('div',[_c('SuccessChart',{attrs:{"data":_vm.cfPictureData,"property":(_vm.pageData.entityUrl === 'supplementalFunding'
                  ? _vm.cfSelectedParts['*1'].solrValue === '1'
                    ? 'Grants'
                    : 'Funding'
                  : _vm.cfSelectedParts['*2'].solrValue === '1'
                  ? 'Grants'
                  : 'Funding') ?? 'Funding'}})],1):(_vm.isMultiYears)?_c('div',[_c('MultiYearChartWithOptions',{attrs:{"data":_vm.cfPictureData,"y-label":(_vm.pageData.entityUrl === 'supplementalFunding'
                  ? _vm.cfSelectedParts['*1']?.text
                  : _vm.cfSelectedParts['*2']?.text) ?? 'Projekte',"filename":_vm.pageData.entityUrl}})],1):_c('div',[_c('WaffleChart',{attrs:{"graph-input":_vm.cfPictureData,"filename":_vm.pageData.entityUrl,"title":_vm.graphTitle,"show-legend":true}})],1)]),(!_vm.showNoPublishedDataMessage)?_c('div',{staticClass:"columns snf-section__columns column-download"},[_c('div',{staticClass:"container column is-full"},[_c('button',{staticClass:"download-button",on:{"click":function($event){return _vm.exportChart()}}},[_vm._v(" "+_vm._s(_vm.exportGraphicText[_vm.selectedLanguage])+" ")]),_c('a',{staticClass:"download-button margin-top-1",attrs:{"href":_vm.pageData.csvUrl}},[_vm._v(" "+_vm._s(_vm.downloadButtonText[_vm.selectedLanguage])+" ")]),_c('a',{staticClass:"download-button margin-top-1",attrs:{"href":_vm.pageData.excelUrl}},[_vm._v(" "+_vm._s(_vm.excelDownloadButtonText[_vm.selectedLanguage])+" ")])])]):_vm._e()])])]):_vm._e(),_c('nav',{staticClass:"cf-nav snf-section snf-section__gray"},[_c('div',{staticClass:"cf-nav__section snf-section__columns columns is-mobile"},[_c('div',{staticClass:"cf-nav__left cf-nav__item column"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/key-figures"}},[_c('span',[_vm._v(_vm._s(_vm.cmsTranslationByKey("BackCoreFiguresHome")))])])],1)])]),(_vm.$route.query.debug)?_c('section',{staticClass:"section snf-section column-download"},[(_vm.cfSolrQuery)?_c('div',{staticStyle:{"margin-top":"3em"}},[_c('table',_vm._l((_vm.cfPictureData),function(entry){return _c('tr',{key:entry.label},[_c('td',{staticStyle:{"width":"300px"}},[_vm._v(" "+_vm._s(entry.label)+" ")]),_c('td',[_vm._v(_vm._s(entry.count))])])}),0),_c('div',{staticStyle:{"margin-top":"2em"}},[_c('a',{attrs:{"href":_vm.cfSolrQuery,"target":"_blank"}},[_vm._v("SOLR Query")])]),_c('pre',{staticStyle:{"margin-top":"2em"}},[_vm._v("      "+_vm._s(_vm.cfPictureData)+"\n      ")])]):_vm._e()]):_vm._e()],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"internationality-map-id"}},[_c('img',{attrs:{"src":"/img/internationality-empty.png"}})])
}]

export { render, staticRenderFns }